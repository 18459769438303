import { useHotkeys } from "react-hotkeys-hook";
import { useHistory, useLocation } from "react-router-dom";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { useAddSpaceModal } from "@circle-react/components/Modals/AddSpaceModal";
import { useCreateEventModal } from "@circle-react/components/Modals/CreateEventModal";
import { useCreateLiveModal } from "@circle-react/components/Modals/CreateLiveModal";
import { useNewSpaceGroupModal } from "@circle-react/components/Modals/NewSpaceGroupModal";
import { useSpotlightSearch } from "@circle-react/components/Modals/SpotlightSearch";
import { useQuickPostV2Modal } from "@circle-react/components/QuickPostV2";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import {
  isBasicPostsSpace,
  isChatSpace,
  isCourseSpace,
  isEventsSpace,
  isMembersSpace,
} from "@circle-react/helpers/spaceHelpers";
import { useSpaceSlugFromUrl } from "@circle-react/hooks/useSpaceSlugFromUrl";
import { useThemeContext } from "@circle-react/providers";
import { useKeyboardShortcutsModal } from "./KeyboardShortcutsModal";
import { KEYBOARD_SHORTCUTS_MAPPER } from "./LeftActionBlock/constants";
import { useRenderNewPostModal } from "./useRenderNewPostModal";

export const useKeyboardShortcuts = () => {
  const keyboardShortcutsModal = useKeyboardShortcutsModal();
  const spotlightSearch = useSpotlightSearch();
  const liveModal = useCreateLiveModal();
  const { toggleAppearance, currentAppearance } = useThemeContext();
  const history = useHistory();
  const quickPostV2Modal = useQuickPostV2Modal();
  const profileDrawer = useProfileDrawer();
  const addSpaceModal = useAddSpaceModal();
  const spaceGroupFormModal = useNewSpaceGroupModal();
  const { currentCommunityMember } = usePunditUserContext();
  const spaceSlug = useSpaceSlugFromUrl();
  const space = useSpacesContext().helpers.findBy({ slug: spaceSlug });
  const createEventModal = useCreateEventModal();
  const renderNewPostModalBasedOnSpaceType = useRenderNewPostModal(space);

  const isCommunityAdminOrModerator =
    isCommunityAdmin(currentCommunityMember) ||
    isCommunityModerator(currentCommunityMember);

  const location = useLocation();
  const isHomePage = location.pathname === "/home";

  const canUserCreateAPost = () => {
    if (isHomePage) return true;

    if (isChatSpace(space)) return false;

    if (isMembersSpace(space) || isCourseSpace(space)) {
      return isCommunityAdminOrModerator;
    }

    if (isBasicPostsSpace(space) || isEventsSpace(space)) {
      return isCommunityAdminOrModerator || space?.policies?.can_create_post;
    }

    return false;
  };

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.open_search,
    event => {
      event.preventDefault();
      if (spotlightSearch.visible) {
        void spotlightSearch.hide();
      } else {
        void spotlightSearch.show();
      }
    },
    [spotlightSearch.visible],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.open_keyboard_shortcuts_modal,
    event => {
      event.preventDefault();
      if (keyboardShortcutsModal.visible) {
        void keyboardShortcutsModal.hide();
      } else {
        void keyboardShortcutsModal.show();
      }
    },
    [keyboardShortcutsModal.visible],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.toggle_appearance,
    event => {
      event.preventDefault();
      toggleAppearance?.();
    },
    [currentAppearance],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.customize_theme,
    event => {
      event.preventDefault();
      history.push("/settings/theme");
    },
    {
      enabled: isCommunityAdmin(currentCommunityMember),
    },
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.navigation.go_to_settings,
    event => {
      event.preventDefault();
      history.push("/settings");
    },
    {
      enabled: isCommunityAdmin(currentCommunityMember),
    },
  );

  useHotkeys(KEYBOARD_SHORTCUTS_MAPPER.navigation.go_to_home, event => {
    event.preventDefault();
    history.push("/home");
  });

  useHotkeys(KEYBOARD_SHORTCUTS_MAPPER.navigation.go_to_members, event => {
    event.preventDefault();
    history.push("/members");
  });

  useHotkeys(KEYBOARD_SHORTCUTS_MAPPER.navigation.go_to_courses, event => {
    event.preventDefault();
    history.push("/courses");
  });

  useHotkeys(KEYBOARD_SHORTCUTS_MAPPER.navigation.go_to_events, event => {
    event.preventDefault();
    history.push("/events");
  });

  useHotkeys(KEYBOARD_SHORTCUTS_MAPPER.basics.edit_profile, event => {
    event.preventDefault();
    history.push("/account");
  });

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.customize_notifications,
    event => {
      event.preventDefault();
      history.push("/account/notifications");
    },
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.navigation.go_live,
    event => {
      event.preventDefault();
      if (liveModal.visible) {
        void liveModal.hide();
      } else {
        void liveModal.show();
      }
    },
    [liveModal.visible],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.view_your_profile,
    event => {
      event.preventDefault();
      if (profileDrawer.visible) {
        void profileDrawer.hide();
      } else {
        void profileDrawer.show({
          memberPublicId: currentCommunityMember?.public_uid ?? "",
        });
      }
    },
    [profileDrawer.visible],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.basics.write_a_post,
    event => {
      event.preventDefault();
      void renderNewPostModalBasedOnSpaceType();
    },
    {
      enabled: canUserCreateAPost(),
    },
    [quickPostV2Modal.visible, createEventModal.visible, space],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.navigation.add_space,
    event => {
      event.preventDefault();
      if (addSpaceModal.visible) {
        void addSpaceModal.hide();
      } else {
        void addSpaceModal.show();
      }
    },
    [addSpaceModal.visible],
  );

  useHotkeys(
    KEYBOARD_SHORTCUTS_MAPPER.navigation.add_space_group,
    event => {
      event.preventDefault();
      if (spaceGroupFormModal.visible) {
        void spaceGroupFormModal.hide();
      } else {
        void spaceGroupFormModal.show();
      }
    },
    [spaceGroupFormModal.visible],
  );
};
