import { useCustomizeSpaceDrawer } from "@circle-react/components/Drawers/CustomizeSpaceDrawer";
import { useAddMemberModal } from "@circle-react/components/Modals/AddSpaceMember";
import { useCreateEventModal } from "@circle-react/components/Modals/CreateEventModal";
import { useQuickPostV2Modal } from "@circle-react/components/QuickPostV2";
import { useSpaceResource } from "@circle-react/contexts";
import type { Space } from "@circle-react/types";

type PostType = "basic" | "event" | "course" | "members";

const isValidPostType = (value: string | undefined): value is PostType =>
  ["basic", "event", "course", "members"].includes(value || "");

export const useRenderNewPostModal = (space?: Space) => {
  const quickPostV2Modal = useQuickPostV2Modal();
  const createEventModal = useCreateEventModal();
  const customizeSpaceDrawer = useCustomizeSpaceDrawer();
  const addMemberModal = useAddMemberModal();
  const { refetch } = useSpaceResource({ spaceId: space?.id });

  const handleBasicPost = async () => {
    if (quickPostV2Modal.visible) {
      await quickPostV2Modal.hide();
    } else {
      await quickPostV2Modal.show({ spaceId: space?.id });
    }
  };

  const handleEventPost = async () => {
    if (createEventModal.visible) {
      await createEventModal.hide();
    } else {
      await createEventModal.show({ spaceId: space?.id });
    }
  };

  const handleCoursePost = async () => {
    if (customizeSpaceDrawer.visible) {
      await customizeSpaceDrawer.hide();
    } else {
      await customizeSpaceDrawer.show({
        spaceId: space?.id,
        refetch: async () => {
          await refetch();
        },
      });
    }
  };

  const handleMembersPost = async () => {
    if (addMemberModal.visible) {
      await addMemberModal.hide();
    } else {
      await addMemberModal.show({ spaceId: space?.id });
    }
  };

  const postTypeHandlers = {
    basic: handleBasicPost,
    event: handleEventPost,
    course: handleCoursePost,
    members: handleMembersPost,
  } as const;

  return async () => {
    const postType = space?.post_type;
    const validPostType = isValidPostType(postType) ? postType : "basic";
    const handler = postTypeHandlers[validPostType];
    await handler();
  };
};
